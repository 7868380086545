<template>
  <div 
    id="hebergement"
  >
    <Menu/>
    <b-container>
      <h1 class="mt-5 mb-5">Notre mariage approche, vous ne savez pas où dormir</h1>
      <p>Les festivités de notre mariage auront lieu du 04 juin 2022 au 06 juin 2022, à Saint- Julien-de-la-Nef. Vous pouvez y participer quand vous le souhaitez, aller & venir en fonction de vos disponibilités, sachant que le coeur du mariage aura lieu le samedi 04 juin2022 !</p>
      <p>Le nombre de chambre étant limité sur place, nous vous invitons à réserver dès que possible votre hébergement à proximité du lieu de réception.</p>
      <p><u>Voici nos suggestions : </u></p>
      <div class="row">
        <b-col class="mt-2" md="6" lg="6">
          <div class="round">
            <p>Camping Isis en Cévennes<br>
              - Mobilhomes : de 2 à 6 pers.<br>
              - Bungalows : de 2 à 8 pers.<br>
              - Insolite (roulotte, bulle...) : de 2 à 4 pers.<br>
              - Emplacement tente<br>
              Brochure en annexe*<br>
              <span><img src="../assets/walker.png" width="8%"/> à 16 min. à pieds du lieu</span><br>
              <img src="../assets/car.png" width="8%"/> à 3 min. en voiture du lieu<br>
              <a href="https://www.isisencevennes.fr/" target="_blank">Site internet</a> - <a href="https://www.isisencevennes.fr/IMG/pdf/tarifs_camping_isis.pdf" target="_blank">Brochure tarifs</a>
            </p>
          </div>
        </b-col>
        <b-col class="mt-2" md="6" lg="6">
          <div class="round">
            <p>
              Vous pouvez aussi rechercher votre logement sur Airbnb ou Booking, dans la zone de Saint-Julien-de-la- Nef, Ganges et les alentours !<br>
              <img src="../assets/trajet.png" width="250px"><br>

              <a href="https://www.airbnb.fr/" target="_blank">Airbnb.fr</a> - <a href="https://www.booking.com/index.fr.html" target="_blank">Booking.com</a>
            </p>
          </div>
        </b-col>
      </div>
      <div class="mt-5 mb-4 d-flex justify-content-center align-items-center">
        <p class="mb-1 mr-4">Nous comptons sur votre votre vigilance pour les trajets en voiture <br>#préventionroutière #sam</p>
        <img src="../assets/cheers.png" width="100px">
      </div>
      <p>Et pour les plus aventuriers d'entre vous, il est possible de planter deux ou trois tentes sur notre lieu de réception. Merci de nous avertir rapidement si vous souhaitez réserver votre place.<br>
      <img src="../assets/camping.png" class="mt-3" alt=""></p>
      <p class="pb-5">Au plaisir de vous retrouver le week-end du 04 juin 2022.</p>
    </b-container>
  </div>
</template>
<script>
import Menu from './../components/Menu.vue'
export default {
  components: {
    Menu
  },
}
</script>
<style lang="scss">
#hebergement {
  .round {
    background-color: #3a5f7e;
    color: white;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    a {
      color: #ff945e;
    }
  }
}
</style>
